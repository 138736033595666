import React, { useContext, useEffect, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import secureLocalStorage from 'react-secure-storage';

const myContext = React.createContext();

export function useContextHook() {
  return useContext(myContext);
}
export function StoreContext({ children }: any) {

const [globalStudies, setGlobalStudies] = useState([])

const [isInsideViewer, setIsInsideViewer] = useLocalStorage('isInsideViewer', false)
const [selectedTemplate, setSelectedTemplate] = useLocalStorage('template', '');
const [selectedModality, setSelectedModality] = useLocalStorage('Modality', 'Default');
const [changeInReportDetected, setChangeInReportDetected ] = useLocalStorage('changeInReportDetected',false)
const [hasSubmittedOrUpdated, setHasSubmittedOrUpdate ] = useLocalStorage('hasSubmitted',false)
const [value, setValue] = useState('');
const [patientData, setPatientData] = useState(
  JSON.parse(localStorage.getItem('patient-info'))
);

  return (
    <myContext.Provider
      value={{
        globalStudies,
        setGlobalStudies,
        isInsideViewer,
        setIsInsideViewer,
        selectedTemplate,
        setSelectedTemplate,
        selectedModality,
        setSelectedModality,
        changeInReportDetected,
        setChangeInReportDetected,
        hasSubmittedOrUpdated,
        setHasSubmittedOrUpdate,
        value, setValue,
        patientData, setPatientData

      }}
    >
      {children}
    </myContext.Provider>
  );
}
