import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './viewReport.css';
// import { aiServices } from '../utils/aiSrevices';
import { BiMessageSquareEdit, BiArrowBack, BiPrinter } from 'react-icons/bi';
import { LoadingIndicatorProgress } from '@ohif/ui';
import useReportingServices from './reportingServices';
import secureLocalStorage from 'react-secure-storage';
import { useReactToPrint } from 'react-to-print';
import { AiFillFileAdd } from 'react-icons/ai';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { extensionManager } from '../../App';
import { flatMap } from 'lodash';
import { useContextHook } from '../../store/store';
const ViewReport = () => {
  const [patientReport, setPatientReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const url = useParams()
  console.log("URL INSIDE VIEW REPROT: ",url)
  const { getStudyReport } = useReportingServices();
  const pdfRef = useRef();
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const {setSelectedModality, setSelectedTemplate, isInsideViewer, patientData} = useContextHook()



  const getPatientReport = async (studyInstanceId) => {

    getStudyReport(studyInstanceId)
      .then(prevReport => {
        console.log("PREVIOUS REPORT inside view Report: ",prevReport)
        setPatientReport(prevReport);
        setIsLoading(false);
      })
      .catch(err => {
        console.log("Finally that error: ",err)
        console.log("Finally err.response.status: ",err.response.status)
        if (err.response.status === 404) {
          console.log("Study not found in view Report")
        }
        setIsLoading(false);
        navigate(`/viewer/write-report/StudyInstanceUIDs=${studyInstanceId}`);
      });
  };

  useEffect(() => {
    if (url.StudyInstanceUIDs && !isInsideViewer){
      console.log("url.StudyInstanceUIDs inside viewReport:",url.StudyInstanceUIDs)
      getPatientReport(url.StudyInstanceUIDs);
      return
    } else {
      getPatientReport(patientData?.studyInstanceUid);
      return
    }
  }, []);

  const handleEdit = e => {
    e.preventDefault();
    if (url.StudyInstanceUIDs && !isInsideViewer){
      // alert('if block inside handleEdit of view report')
      navigate(`/viewer/write-report/StudyInstanceUIDs=${url.StudyInstanceUIDs}`);
      return
    } else {
      // alert('else block inside handleEdit of view report')
      navigate(`/viewer/write-report/StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
      return
    }
    // navigate(`/viewer/write-report?StudyInstanceUIDs=${patientStudyId}`);
  };
  const handleBack = e => {
    e.preventDefault();

    setSelectedTemplate('')
    setSelectedModality('Default')
    navigate('/');
    window.close()
    // const onClickReturnButton = () => {
    //   const { pathname } = location;
    //   const dataSourceIdx = pathname.indexOf('/', 1);
    //   const query = new URLSearchParams(window.location.search);
    //   const configUrl = query.get('configUrl');

    //   const dataSourceName = pathname.substring(dataSourceIdx + 1);
    //   const existingDataSource = extensionManager.getDataSources(dataSourceName);

    //   const searchQuery = new URLSearchParams();
    //   if (dataSourceIdx !== -1 && existingDataSource) {
    //     searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    //   }

    //   if (configUrl) {
    //     searchQuery.append('configUrl', configUrl);
    //   }

    //   navigate({
    //     pathname: '/',
    //     search: decodeURIComponent(searchQuery.toString()),
    //   });
    // };
    // onClickReturnButton();
    // navigate(`/viewer?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
  };

  console.log("PatientReport: ",patientReport)
  console.log("patientReport?.description: ",patientReport?.description)
  console.log("patientReport?.description != undefined:  ",patientReport?.description != undefined)

  return (
    <>
      {isLoading ? (
        <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
      ) : (
        <div className="report_container">
          <div className="appbar">
            <a
              className="back_btn"
              onClick={handleBack}
            >
              <BiArrowBack />
              <span style={{ fontSize: '15px' }}>Back</span>
            </a>
            <div className="action_btns">
              <a
                className="back_btn"
                onClick={handleEdit}
              >
                <BiMessageSquareEdit />
                <span style={{ fontSize: '15px' }}>
                  {patientReport?.description ? 'Edit Report' : 'Create Report'}
                </span>
              </a>
              <a
                className="back_btn"
                onClick={handlePrint}
              >
                <BiPrinter />
                <span style={{ fontSize: '15px' }}>{'Print'}</span>
              </a>
            </div>
          </div>
          {/* <img src={require('./assets/logo1.jpg').default} alt=""></img> */}
          <div className="report__section w-3/5">
            <div
              className="report_data"
              ref={pdfRef}
            >
              {(patientReport?.description != undefined) ? (
                <div
                  dangerouslySetInnerHTML={{ __html: patientReport?.description }}
                  id="#report"
                /> ) : (
                <div className="flex justify-center ">
                  <div className="bg-secondary-light flex w-3/5 flex-col items-center justify-center p-8 text-white">
                    <AiFillFileAdd className="h-20 w-20 " />

                    <h2 className="text-center text-white">Report not added yet. </h2>
                    <p className="text-center text-white">Click here to add your report...</p>
                    <button
                      className="button mt-4 "
                      onClick={handleEdit}
                    >
                      Create Report
                    </button>

                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewReport;
