import userServices from '../User/userServices';

const useReportingServices = () => {
  const { makeRequest, setData, handleAlert } = userServices();

  function getStudyReport(studyId) {
    return new Promise((resolve, reject) => {
      console.log("STUDY ID inside Reporting Service: ",studyId)

      makeRequest('v1/report/?study_id=' + studyId, 'get')
      // makeRequest('v1/report/?study_id=' + '1.2.3.471775818', 'get')
        .then(report => {
          resolve(report);
        })
        .catch(error => {
          console.log("ERROR inside get Study Report: ",error)
          reject(error);
        });
    });
  }
  function getAIReport(studyId) {
    return new Promise((resolve, reject) => {
      makeRequest('v1/aireport/?study_id=' + studyId, 'get')
        .then(report => {
          resolve(report);
        })
        .catch(error => {
          console.log("Error inside getAI Report: ", error)
          reject(error);
        });
    });
  }

  function addStudyReport(studyId, reportBody) {
    return new Promise((resolve, reject) => {
      setData('v1/report/', 'post', reportBody)
        .then(report => {
          // localStorage.setItem('report_status', 'true');
          handleAlert('success', 'Report Submitted Successfully.');
          resolve(report);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  function updateStudyReport(studyId, reportBody) {
    return new Promise((resolve, reject) => {
      setData('v1/report/?study_id=' + studyId, 'PATCH', reportBody)
        .then(report => {
          // localStorage.setItem('patient_report', JSON.stringify(report));
          // aiServices.handleAlert('success', 'Updated Successfully');
          resolve(report);
        })
        .catch(error => {
          // aiServices.handleAlert('error', error);
          reject(error);
        });
    });
  }

  function getReportIds() {
    return new Promise(async (resolve, reject) => {
      makeRequest('v1/reportIds/', 'get')
        .then(reportIds => {
          // localStorage.setItem('reportIds', JSON.stringify(reportIds));
          resolve(reportIds);
          // dispatch(setReportIds(reportIds));
          return reportIds;
        })
        .catch(error => {
          // aiServices.handleAlert('error', error);
          reject(error);
        });
    });
  }
  return {
    getStudyReport,
    getAIReport,
    addStudyReport,
    updateStudyReport,
    getReportIds,
  };
};
export default useReportingServices;
