import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { config } from './config/config';
import JoditEditor, { Jodit } from 'jodit-react';
import { Appbar, ConfirmationDialog } from './components';
// import { useLocalStorage } from '../../hooks';
import { getHeader, getFooter, getBody } from './helpers';
import { templates, templateModalities } from './templates/templates';
import secureLocalStorage from 'react-secure-storage';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './addReport.css';
import useReportingServices from './reportingServices';
import userServices from '../User/userServices';
import { LoadingIndicatorProgress } from '@ohif/ui';
import { Button as CustomButton } from '@ohif/ui';

import getAiReportBody  from './helpers/getAiReportBody';
import { AiFillFileAdd } from 'react-icons/ai';
import { useContextHook } from '../../store/store';
import { Select } from '@ohif/ui';
import { extensionManager } from '../../App';
import { error } from 'console';
// import logo from "../../../public"


const AddReport = () => {
  const editor = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [aiReport, setAiReport] = useState({});
  const [isAIReportPreview, setIsAIReportPreview] = useState(false);
  const [flag, setFlag] = useState(false);
  const [patientReport, setPatientReport] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const { getStudyReport, getAIReport, addStudyReport, updateStudyReport } = useReportingServices();
  const { handleAlert } = userServices();
  const navigate = useNavigate();
  const url = useParams()
  console.log("URL inside add report: ",url)

  const { globalStudies,  isInsideViewer, setIsInsideViewer,      selectedTemplate,
    setSelectedTemplate,
    selectedModality,
    setSelectedModality,
    changeInReportDetected,
    setChangeInReportDetected,
    hasSubmittedOrUpdated,
    setHasSubmittedOrUpdate, value, setValue, patientData, setPatientData} = useContextHook()


let studyInstanceIdFromUrl = url.StudyInstanceUIDs.split('=')[1];


  console.log("NUMBER: ",studyInstanceIdFromUrl);

  useEffect(() => {
    // Get Normal Report (Created)
    const getPatientReport = async (studyId) => {
      // getStudyReport(patientData?.studyInstanceUid)
      getStudyReport(studyId)
      .then(prevReport => {
        console.log("prevReport inside Add report: ",prevReport)
        setPatientReport(prevReport);
        setIsLoading(false);
        setIsNew(false);
      })
      .catch(err => {
        console.log("Finally inside add report err.response.status", err.response.status)
        if (err.response.status === 404){
          // setPatientReport(undefined);
            console.log("Pateint report not found in study")
          }
          setIsLoading(false);
        });
    };
    // Get AI Report for patient

    const getAiReport = async (studyId) => {
      // await getAIReport(patientData?.studyInstanceUid)
      await getAIReport(studyId)
        .then(report => {
          setAiReport(report);
          console.log('aiReport', report);
          setFlag(true);
        })
        .catch(error => {
          console.info('AI report Status: False');
        });
    };

    if (studyInstanceIdFromUrl && !isInsideViewer){
      getPatientReport(studyInstanceIdFromUrl);
      getAiReport(studyInstanceIdFromUrl);
      return
    } else {
      getPatientReport(patientData?.studyInstanceUid);
      getAiReport(patientData?.studyInstanceUid);
      return

    }
  }, [submit]);

  useEffect(() => {
    const handleUnload = e => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave this page?';
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  let initt =
  patientReport != undefined && !isNew
      ? patientReport?.description
      : isAIReportPreview
        ? `${getHeader(patientData, '')}<br/>${getAiReportBody(aiReport)}`
        : `${getHeader(patientData, '')}<br/>${getBody(
            selectedModality?.value,
            selectedTemplate?.id
          )}${getFooter()} `;

  console.log("initt: ",initt)

  // Report Submission
  const handleSubmit = async e => {
    e.preventDefault();
    // alert('handle submitted got clicked')
    console.log("patient data inside handle submit of add report: ",patientData)

    let body = {
      study_id: patientData.studyInstanceUid,
      patient_id:  patientData.mrn,
      patient_name: patientData.patientName,
      // description: value ? value : patientData,
      description: value ?  value : `${getHeader(patientData, '')}<br/>${getBody(
          selectedModality?.value,
          selectedTemplate?.id
        )}${getFooter()} `,
    };
    console.log("body body body: ",body)
    if (body.description === "" || body.description === undefined) {
      handleAlert('error','Empty report cannot be submitted!')
      return
    }

    console.log("body?.description inside addreport: ",body?.description)

    // alert('Handle submitted got clicked')
    await addStudyReport(patientData.studyInstanceUid, body);
    setIsNew(false);
    setSubmit(!submit);
    setChangeInReportDetected(false)
    setHasSubmittedOrUpdate(true)
    navigate(`/viewer/view-report/${patientData.studyInstanceUid}`)

  };

  // handle report updates
  const handleUpdate = async (e:any, setShowUpdateButton:Boolean) => {
    e.preventDefault();
    // alert('handle update inside add report')
    let body = {
      description: value,
    };
    if (body.description === "" || body.description === undefined) {
      handleAlert('error','Empty report cannot be submitted!')
      return
    }

    if (studyInstanceIdFromUrl && !isInsideViewer) {
      // alert('if block inside handle update of add report')
      await updateStudyReport(studyInstanceIdFromUrl, body);
      navigate(`/viewer/view-report/${studyInstanceIdFromUrl}`)
    } else {
      // alert('else block inside handle update of add report')
      await updateStudyReport(patientData?.studyInstanceUid, body);
      navigate(`/viewer/view-report/${patientData?.studyInstanceUid}`)
    }

    setIsNew(false);
    setSubmit(!submit);
    setChangeInReportDetected(false)
    setHasSubmittedOrUpdate(true)
    // alert('update button inside add report clickec!')
    handleAlert('success', 'Updated Successfully...');

  };

  console.log("Patient Report inside add report: ",patientReport)

  const handleBackWithSave = async e => {
    e.preventDefault();
    if (patientReport) {
      // alert('if block handleback with save ')
      let body = {
        // description: value,
        description:value ? value : `${getHeader(patientData, '')}<br/>${getBody(
          selectedModality?.value,
          selectedTemplate?.id
        )}${getFooter()} `
      };
      if (body.description === "" || body.description === undefined) {
        handleAlert('error','Empty report cannot be submitted!')
        return
      }
      if (studyInstanceIdFromUrl && !isInsideViewer) {
        // alert('if block of backwithSave inside add report')
        await updateStudyReport(studyInstanceIdFromUrl, body);
      } else {
        // alert('else block of backwithSave inside add report')
        await updateStudyReport(patientData?.studyInstanceUid, body);
      }
      setHasSubmittedOrUpdate(true)
      handleAlert('success', 'Updated Successfully...');
    } else {
      let body = {
        study_id: patientData.studyInstanceUid,
        patient_id: patientData.mrn,
        patient_name: patientData.patientName,
        description: value ?  value : `${getHeader(patientData, '')}<br/>${getBody(
          selectedModality?.value,
          selectedTemplate?.id
        )}${getFooter()} `,
        // description: value,
      };
      await addStudyReport(patientData.studyInstanceUid, body);
      setHasSubmittedOrUpdate(true)
      handleAlert('success', 'Submitted Successfully...');
      // alert('submit button got clicked!')
    }
    setSelectedTemplate('')
    setSelectedModality('Default')
    setChangeInReportDetected(false)
    getReportData('')

    // secureLocalStorage.setItem('Modality', 'Default');
    // secureLocalStorage.setItem('template', '');

    // history.push(`/viewer/${studyData.studyInstanceUid}`);

    // secureLocalStorage.removeItem('patient-info')
    window.close();
    navigate('/')
    // navigate(`/viewer/write-report/StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
    // navigate(`/viewer?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
  };

  const handleBackWithoutSave = async e => {
    e.preventDefault();
    setSelectedTemplate('')
    setSelectedModality('Default')
    setChangeInReportDetected(false)
    setHasSubmittedOrUpdate(false)

        window.close();
        navigate(`/`);

    // alert('handle back without save inside add report')

    // navigate(-1);
    // navigate(`/viewer?StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
    // secureLocalStorage.removeItem('patient-info')
    // secureLocalStorage.setItem('Modality', 'Default');
    // secureLocalStorage.setItem('template', '');


    // const onClickReturnButton = () => {
    //   const { pathname } = location;
    //   const dataSourceIdx = pathname.indexOf('/', 1);
    //   const query = new URLSearchParams(window.location.search);
    //   const configUrl = query.get('configUrl');

    //   const dataSourceName = pathname.substring(dataSourceIdx + 1);
    //   const existingDataSource = extensionManager.getDataSources(dataSourceName);

    //   const searchQuery = new URLSearchParams();
    //   if (dataSourceIdx !== -1 && existingDataSource) {
    //     searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    //   }

    //   if (configUrl) {
    //     searchQuery.append('configUrl', configUrl);
    //   }

    //   navigate({
    //     pathname: '/',
    //     search: decodeURIComponent(searchQuery.toString()),
    //   });
    // };
    // onClickReturnButton();
  };

  // Confirmation Dialogue
  function handleBackWithoutSaveNoChangeDetected () {
    // alert('handleBackWithoutSaveNoChangeDetected called')
    setSelectedTemplate('')
    setSelectedModality('Default')
    setChangeInReportDetected(false)
    window.close();
    navigate(`/`);
    return
  }
  const handleOpenDialog = () => {
    if (!changeInReportDetected){
      handleBackWithoutSaveNoChangeDetected()
      return
    }
    if (changeInReportDetected && !hasSubmittedOrUpdated) {
      // alert('dialogue box will open')
      setOpenDialog(true);
      return
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getReportData = val => {
    // alert('GetReportData called')
    console.log("val123 after that: ", val)
    setValue(val);
  };

  const aiReportToggle = () => {
    setIsAIReportPreview(!isAIReportPreview);
    // window.location.reload(false);
  };

  const handleTemplateChange = (newValue: string) => {
    setSelectedTemplate(newValue);
  };
  const handleModalityChange = (newValue: string) => {
    setSelectedModality(newValue);
  };

  const handleEdit = e => {
    e.preventDefault();
    setHasSubmittedOrUpdate(false)
    // alert('edit button from add report ')
    if (studyInstanceIdFromUrl && !isInsideViewer){
      // alert('entered if block')
      const selectedStudy = globalStudies.filter(study => study.studyInstanceUid == studyInstanceIdFromUrl)
      console.log("selectedStudy inside NUMBER: ",selectedStudy)
      localStorage.setItem('patient-info',JSON.stringify(selectedStudy));
      console.log("selectedStudy[0]: ",selectedStudy[0])
      setPatientData(selectedStudy[0])
      console.log('patient data after that: ', patientData)

      setPatientReport( prevReport => ({
        ...prevReport,
        description:`${getHeader(patientData, '')}<br/>${getBody(
          selectedModality?.value,
          selectedTemplate?.id
        )}${getFooter()} `
      }))

     initt = `${getHeader(patientData, '')}<br/>${getBody(
          selectedModality?.value,
          selectedTemplate?.id
        )}${getFooter()} `




      navigate(`/viewer/write-report/StudyInstanceUIDs=${studyInstanceIdFromUrl}`);

    } else {

    setPatientReport(patientData)
    getReportData(()=>{ return `${getHeader(patientData, '')}<br/>${getBody(
      selectedModality?.value,
      selectedTemplate?.id
    )}${getFooter()} `})

      navigate(`/viewer/write-report/StudyInstanceUIDs=${patientData?.studyInstanceUid}`);
    }

  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center pt-48">
          <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
        </div>
      ) : (
        <div className="position-relative flex min-h-screen flex-col items-center bg-white ">
            <Appbar
            flag={flag}
            setFlag={setFlag}
            patientReport={patientReport}
            isNew={isNew}
            setIsNew={setIsNew}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            templateOptions={templates[selectedModality?.value]}
            templateModalities={templateModalities}
            signatures={[]}
            selectedModality={selectedModality}
            setSelectedModality={setSelectedModality}
            handleOpenDialog={handleOpenDialog}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            isAIReportPreview={isAIReportPreview}
            setIsAIReportPreview={setIsAIReportPreview}
            // selectedSignature={selectedSignature}
            // setSelectedSignature={setSelectedSignature}
          />

          <div className="mt-24 w-3/5 overflow-x-hidden">
            <div className="editor__container">
              <div className="editor">
                 {/* <JoditEditor
                  className="jodit_editor"
                  ref={editor}
                  value={initt}
                  config={config}
                  // tabIndex={1}
                  //   onBlur={(newContent) => getValue(newContent)}
                  onChange={newContent => getReportData(newContent)}
                /> */}
                {patientReport != undefined ? <JoditEditor
                  className="jodit_editor"
                  ref={editor}
                  value={initt}
                  config={config}
                  // tabIndex={1}
                  //   onBlur={(newContent) => getValue(newContent)}
                  // onChange={handleChange}
                  onChange={newContent => {
                    // alert('change in report detected')
                    setChangeInReportDetected(true)
                    setHasSubmittedOrUpdate(false)
                    return getReportData(newContent)}}
                /> : ( <div className="flex justify-center ">
                  <div className="bg-secondary-light flex w-3/5 flex-col items-center justify-center p-8 text-white">
                    <AiFillFileAdd className="h-20 w-20 " />
                    <h1>Part 2</h1>

                    <h2 className="text-center text-white">Report not added yet. </h2>
                    <p className="text-center text-white">Click here to add your report...</p>
                    <div className='lg:flex lg:flex-row items-center lg:align-middle mt-4 lg:space-x-4'>

                    <button
                      className="button"
                      onClick={handleEdit}
                    >
                      Create Report
                    </button>
                    <Select
          id="select-template-modality"
          className={

               'min-w-36 border-secondary-main relative lg:mr-3  bg-transparent text-white'

          }
          value={selectedModality}
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          closeMenuOnSelect={true}
          hideSelectedOptions={true}
          options={templateModalities}
          onChange={handleModalityChange}
          placeholder="Select Modality"
        />

        { selectedModality !== 'Default' && <Select
          id="select-template"
          className={

               'min-w-72 md:min-w-40  sm:min-w-10 relative mr-3 border-white bg-transparent text-white'

          }
          value={selectedTemplate}
          isMulti={false}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          hideSelectedOptions={true}
          options={templates[selectedModality?.value]}
          onChange={handleTemplateChange}
          placeholder="Select Template"
        />}
                    </div>
                  </div>

                </div>)}

              </div>
              {/* <div className="viewer "> */}
              {/* <h2>Viewer</h2> */}
              {/* <img src={require(`../../../public/assets/CMH.png`).default}></img> */}
              {/* {value}  */}
              {/* <div dangerouslySetInnerHTML={{ __html: value }} /> */}
              {/* </div> */}
            </div>
          </div>
          <br />

          </div>
      )}
      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <h1 className="text-xl font-semibold text-white">Do you want to save changes?</h1>
        <p className="text-lg text-white">
          If you choose "No", the content will be deleted permanently, else the report will be
          saved.
        </p>
        <br />
        <div className="modal__btns">

          <CustomButton
          onClick={handleBackWithoutSave}
            className="bg-secondary-main p-1 text-white"
            >
            No
          </CustomButton>
          <CustomButton
            onClick={handleBackWithSave}
            className="bg-secondary-main p-1 text-white"
          >
            Yes
          </CustomButton>
        </div>

      </Modal>
    </>
  );
};

export default AddReport;
// {/* <img src={require('./clientLogos/AFIRI.jpg').default} /> */}
