import React, { useState, useEffect } from 'react';
// import './styling.css';
import { Icon, Svg } from '@ohif/ui';
import { useNavigate } from 'react-router-dom';
import userServices from './userServices';

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [loginError, setLoginError] = useState({});

  const [todayDate, setTodayDate] = useState<number>();
  const isFormValid = username && password;

  const navigate = useNavigate();
  const { login, handleAlert } = userServices();

  // let enableUser = false;
  useEffect(() => {
    fetch('http://worldtimeapi.org/api/ip')
      .then(response => response.json())
      .then(data => {
        const currentDateTime = new Date(data.datetime);
        setTodayDate(() => currentDateTime.getTime());
      })
      .catch(error => {
        console.log('Error fetching date from the internet:', error);
      });
  }, []);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const expiryDate = new Date('2040-06-22');
    const generateRandomString = () => {
      const randomString = Math.random().toString();
      return randomString;
    };

    const date = new Date();
    if (expiryDate.getTime() < todayDate || expiryDate.getTime() < date.getTime()) {
      alert(date.toLocaleDateString());
      navigate('/licenseexpiry');
    } else {
      try {
        await login(username, password);
        setLoginError({});
        // await serverConfig();
      } catch (error) {
        if (error?.response?.status == '401') {
          setLoginError(() => {
            return error?.response;
          });
          handleAlert('error', 'Authentication Error.');
          error?.response?.data.attempts == 0 ? navigate('/accountblocked') : '';
        } else if (error?.response?.status == '409') {
          navigate(`/accountblocked`);
          handleAlert('error', 'Your account is temporarily blocked.');

          // setLoginError(() => {
          //   return error?.response;
          // });
        } else {
          handleAlert('error', 'Connection Failed...');
        }
        throw error;
      }
    }
  };
  return (
    <div>
      <div className="flex h-[100vh] w-full flex-col items-center justify-center bg-black px-5">
        <div className=" mb-2 flex w-full flex-col  items-end justify-start overflow-hidden xl:max-w-2xl "></div>
        <div className="w-[500px] border-2  border-blue-700 bg-black p-5 sm:p-10 xl:max-w-2xl">
          <h1
            className="text-center text-xl font-semibold text-white
              sm:text-3xl"
          >
            <div className="mb-2 grid place-items-center">
              <Svg
                name="logo-xylexa"
                style={{ width: '12rem' }}
              />
            </div>
          </h1>
          <form
            className="mt-8 w-full"
            onSubmit={handleSubmit}
          >
            <div className="mx-auto flex max-w-xs flex-col gap-4 sm:max-w-md md:max-w-lg">
              <input
                id="username"
                className="w-full  border-2  border-transparent bg-[#302E30] px-5 py-3 text-sm font-medium text-white  placeholder-gray-500 focus:border-2 focus:border-white focus:outline-none focus:outline"
                type="tel"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
              />
              <div className="flex flex-row rounded-none bg-[#302E30]">
                <input
                  id="password"
                  className="w-full  border-2 border-transparent  bg-[#302E30] px-5 py-3 text-sm font-medium text-white placeholder-gray-500  focus:border-2 focus:border-white focus:outline-none focus:outline"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <span className="bg-[#302E30]">
                  {!showPassword && (
                    <button
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="bg-[#302E30]"
                    >
                      <Icon
                        name="eye-visible"
                        style={{ width: '25', height: '25',color:'white' }}
                      />
                    </button>
                  )}

                  {showPassword && (
                    <button
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="bg-[#302E30]"
                    >
                      <Icon
                        name="eye-hidden"
                        style={{ width: '25', height: '25', color:'white' }}
                      />
                    </button>
                  )}
                </span>
              </div>
              <div className={!loginError?.data?.detail ? 'hidden' : ' w-60  text-red-600'}>
                {loginError?.status == '401' ? (
                  <p className="overflow-hidden  overflow-ellipsis">
                    Invalid Password! <br />
                    You have {loginError?.data?.attempts ? loginError?.data?.attempts : 4} attempts
                    left.
                  </p>
                ) : loginError?.status == '409' ? (
                  <p className="overflow-hidden  overflow-ellipsis"> {loginError?.data?.detail} </p>
                ) : (
                  ''
                )}
              </div>
              <button
                className={`focus:shadow-outline mt-5 flex w-full items-center justify-center bg-[#8f1a1c] py-4 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out ${
                  !isFormValid ? 'hover:cursor-not-allowed' : 'hover:bg-[#8f1a1c]/90'
                } focus:outline-none`}
                type="submit"
                disabled={!isFormValid}
              >
                <svg
                  className="-ml-2 h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                  <circle
                    cx="8.5"
                    cy="7"
                    r="4"
                  />
                  <path d="M20 8v6M23 11h-6" />
                </svg>
                <span className="ml-3">LOGIN</span>
              </button>
              <p className="mt-6 text-center text-sm text-gray-600">
                For account setup or technical queries,{' '}
                <a
                  href="https://xylexa.ai/contact-us/"
                  target="_blank"
                  rel="no-referrer noreferrer"
                >
                  <span className="font-semibold text-[#2c55e9]">Contact us</span>
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
      <p className="-mt-8 text-center text-white">XyCAD Version 3.0 | Xylexa, Inc. © 2024</p>
    </div>
  );
};

export default Login;
