import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
//
import filtersMeta from './filtersMeta.js';
import { useAppConfig } from '@state';
import { useDebounce, useSearchParams } from '@hooks';
import { utils, hotkeys, ServicesManager } from '@ohif/core';
import userServices from '../User/userServices.js';
import secureLocalStorage from 'react-secure-storage';
import useReportingServices from '../Reporting/reportingServices';

import {
  Icon,
  StudyListExpandedRow,
  LegacyButton,
  EmptyStudies,
  StudyListTable,
  StudyListPagination,
  StudyListFilter,
  TooltipClipboard,
  Header,
  useModal,
  AboutModal,
  UserPreferences,
  LoadingIndicatorProgress,
  useSessionStorage,
} from '@ohif/ui';

import i18n from '@ohif/i18n';
import useLocalStorage from '../../hooks/useLocalStorage.js';

import { useContextHook } from '../../store/store';

const { sortBySeriesDate } = utils;

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const seriesInStudiesMap = new Map();

/**
 * TODO:
 * - debounce `setFilterValues` (150ms?)
 */
function WorkList({
  data: studies,
  dataTotal: studiesTotal,
  isLoadingData,
  dataSource,
  hotkeysManager,
  dataPath,
  onRefresh,
  servicesManager,
}) {
  console.log('STUDIESS: ', studies);

  const {setGlobalStudies, setIsInsideViewer, isInsideViewer} = useContextHook()
  useEffect(()=>{
    setGlobalStudies(studies)
  },[studies])






  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const { show, hide } = useModal();
  const { t } = useTranslation();
  const services = userServices();
  const [isTriaged, setIsTriaged] = useState(false);
  // const [mystudies, myfilteredStudies] = useState(studies)

  // ~ Modes
  const [appConfig] = useAppConfig();
  // ~ Filters
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const STUDIES_LIMIT = 101;

  const queryFilterValues = _getQueryFilterValues(searchParams);
  // console.log('QUERY FILTER VALUES: ', queryFilterValues);
  const [sessionQueryFilterValues, updateSessionQueryFilterValues] = useSessionStorage({
    key: 'queryFilterValues',
    defaultValue: queryFilterValues,
    // ToDo: useSessionStorage currently uses an unload listener to clear the filters from session storage
    // so on systems that do not support unload events a user will NOT be able to alter any existing filter
    // in the URL, load the page and have it apply.
    clearOnUnload: true,
  });
  const [filterValues, _setFilterValues] = useState({
    ...defaultFilterValues,
    ...sessionQueryFilterValues,
  });
  // console.log('FILTER VALUES: ', filterValues);

  const debouncedFilterValues = useDebounce(filterValues, 200);
  const { resultsPerPage, pageNumber, sortBy, sortDirection } = filterValues;

  //report ids
  const { getReportIds } = useReportingServices();

  // Responsiveness
  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
  });
  const handleResize = () => {
    setViewportSize({
      width: window.innerWidth,
    });
  };

  useEffect(() => {

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*
   * The default sort value keep the filters synchronized with runtime conditional sorting
   * Only applied if no other sorting is specified and there are less than 101 studies
   */

  const canSort = studiesTotal < STUDIES_LIMIT;
  const shouldUseDefaultSort = sortBy === '' || !sortBy;
  const sortModifier = sortDirection === 'descending' ? 1 : -1;
  const defaultSortValues =
    shouldUseDefaultSort && canSort ? { sortBy: 'studyDate', sortDirection: 'ascending' } : {};

  const sortedStudies = studies;
  // const [sortedStudies, setSortedStudies] = useState(studies)

  // useEffect(() => {
  //   if (studies.length > 0) {
  //     setSortedStudies(studies);
  //   }
  // }, [studies]);

  // if (studies.length === 0) {
  //   return ( <div className="flex flex-col items-center justify-center pt-48">
  //     {appConfig.showLoadingIndicator && isLoadingData ? (
  //       <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
  //     ) : (
  //       <EmptyStudies />
  //     )}
  //   </div>)
  // }

  if (canSort) {
    studies.sort((s1, s2) => {
      if (shouldUseDefaultSort) {
        const ascendingSortModifier = -1;
        return _sortStringDates(s1, s2, ascendingSortModifier);
      }

      const s1Prop = s1[sortBy];
      const s2Prop = s2[sortBy];

      if (typeof s1Prop === 'string' && typeof s2Prop === 'string') {
        return s1Prop.localeCompare(s2Prop) * sortModifier;
      } else if (typeof s1Prop === 'number' && typeof s2Prop === 'number') {
        return (s1Prop > s2Prop ? 1 : -1) * sortModifier;
      } else if (!s1Prop && s2Prop) {
        return -1 * sortModifier;
      } else if (!s2Prop && s1Prop) {
        return 1 * sortModifier;
      } else if (sortBy === 'studyDate') {
        return _sortStringDates(s1, s2, sortModifier);
      }

      return 0;
    });
  }

  // ~ Rows & Studies
  const [expandedRows, setExpandedRows] = useState([]);
  const [studiesWithSeriesData, setStudiesWithSeriesData] = useState([]);
  const [reportIds, setReportIds] = useLocalStorage('reportedPaientIds', []);

  const numOfStudies = studiesTotal;
  const [filteredList, setFilteredList] = React.useState(studies);
  // Handling change in prediction filter values
  useEffect(() => {
    // Persisting and filtering data using queryFilterValues:
    if (queryFilterValues?.referringPhysicianName.length !==0) {
      const predictionsfilterStudiesForNormal = studies.filter((study) => {
        return study?.referringPhysicianName?.toLowerCase().includes(queryFilterValues?.referringPhysicianName[0]?.toLowerCase())});
      const predictionsfilterStudiesForSus = studies.filter((study) => {
        return study?.referringPhysicianName?.toLowerCase().includes(queryFilterValues?.referringPhysicianName[1]?.toLowerCase())});

      const predictionsfilterStudies = [...predictionsfilterStudiesForNormal, ...predictionsfilterStudiesForSus]
        if (predictionsfilterStudies.length !==0) {
          setFilteredList(predictionsfilterStudies)
          }
    } else {
      setFilteredList(studies);
    }

  }, [studies]);




  useEffect(() => {
  // const getReportIds = () => {};
  getReportIds().then(data => {
    setReportIds(data?.study_ids);
  });
  }, []);

//   useEffect(()=>{
//     setSortedStuies(studies)
//      setFilterValues(defaultFilterValues);
//      setIsTriaged(false);
// },[])

// console.log("numOFStudies: ",numOfStudies);

  const querying = useMemo(() => {
    return isLoadingData || expandedRows.length > 0;
  }, [isLoadingData, expandedRows]);


  const setFilterValues = val => {

    if (filterValues.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }
    _setFilterValues(val);
    updateSessionQueryFilterValues(val);
    setExpandedRows([]);
  };

  const onPageNumberChange = newPageNumber => {
    const oldPageNumber = filterValues.pageNumber;
    const rollingPageNumberMod = Math.floor(101 / filterValues.resultsPerPage);
    const rollingPageNumber = oldPageNumber % rollingPageNumberMod;
    const isNextPage = newPageNumber > oldPageNumber;
    const hasNextPage =
      Math.max(rollingPageNumber, 1) * resultsPerPage < numOfStudies &&
      rollingPageNumberMod >= newPageNumber;

    if (isNextPage && !hasNextPage) {
      return;
    }

    setFilterValues({ ...filterValues, pageNumber: newPageNumber });
  };

  const onResultsPerPageChange = newResultsPerPage => {
    setFilterValues({
      ...filterValues,
      pageNumber: 1,
      resultsPerPage: Number(newResultsPerPage),
    });
  };

  // Set body style
  useEffect(() => {
    document.body.classList.add('bg-black');
    return () => {
      document.body.classList.remove('bg-black');
    };
  }, []);

  // Sync URL query parameters with filters
  useEffect(() => {
    if (!debouncedFilterValues) {
      return;
    }

    const queryString = {};
    Object.keys(defaultFilterValues).forEach(key => {
      const defaultValue = defaultFilterValues[key];
      const currValue = debouncedFilterValues[key];
      // console.log('KEY: ', key);
      // console.log('CURR VALUE: ', currValue);

      // TODO: nesting/recursion?
      if (key === 'studyDate') {
        if (currValue.startDate && defaultValue.startDate !== currValue.startDate) {
          queryString.startDate = currValue.startDate;
        }
        if (currValue.endDate && defaultValue.endDate !== currValue.endDate) {
          queryString.endDate = currValue.endDate;
        }
      } else if (key === 'modalities' && currValue.length) {
        queryString.modalities = currValue.join(',');
      } else if (key === 'referringPhysicianName' && currValue.length) {
        queryString.referringPhysicianName = currValue.join(',');
      } else if (currValue !== defaultValue) {
        queryString[key] = currValue.length ? `*${currValue}*` : defaultValue;
        // queryString[key] = currValue;
      }
    });

    const search = qs.stringify(queryString, {
      skipNull: true,
      skipEmptyString: true,
    });

    navigate({
      pathname: '/',
      search: search ? `?${search}` : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValues]);

  // get Report Ids
  useEffect(() => {
    // const getReportIds = () => {};
    getReportIds().then(data => {
      setReportIds(data?.study_ids);
    });
  }, []);
  // Query for series information
  useEffect(() => {
    const fetchSeries = async studyInstanceUid => {
      try {
        const series = await dataSource.query.series.search(studyInstanceUid);
        seriesInStudiesMap.set(studyInstanceUid, sortBySeriesDate(series));
        setStudiesWithSeriesData([...studiesWithSeriesData, studyInstanceUid]);
      } catch (ex) {
        // TODO: UI Notification Service
        console.warn(ex);
      }
    };

    // TODO: WHY WOULD YOU USE AN INDEX OF 1?!
    // Note: expanded rows index begins at 1
    for (let z = 0; z < expandedRows.length; z++) {
      const expandedRowIndex = expandedRows[z] - 1;
      const studyInstanceUid = sortedStudies[expandedRowIndex].studyInstanceUid;

      if (studiesWithSeriesData.includes(studyInstanceUid)) {
        continue;
      }

      fetchSeries(studyInstanceUid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows, studies]);

  const isReported = studyId => {
    const response = reportIds?.find(id => {
      return id === studyId;
    });
    // console.log("RESPONSE inside IsReported: ",response)
    return response;
  };

  const isFiltering = (filterValues, defaultFilterValues) => {
    return !isEqual(filterValues, defaultFilterValues);
  };

  function getPrediction(str) {

    if (str === undefined) {
      return 'undefined';
    } else {
      const newArray = str.split(' ');
      if (newArray.includes('Normal')) {
        return 'Normal';
      }
      if (newArray.includes('Suspicious')) {
        return 'Suspicious';
      } else {
        return '-';
      }
    }
  }

  const rollingPageNumberMod = Math.floor(101 / resultsPerPage);
  const rollingPageNumber = (pageNumber - 1) % rollingPageNumberMod;
  const offset = resultsPerPage * rollingPageNumber;
  const offsetAndTake = offset + resultsPerPage;
  const tableDataSource = filteredList.map((study, key) => {
    const rowKey = key + 1;
    const isExpanded = expandedRows.some(k => k === rowKey);
    const {
      studyInstanceUid,
      accession,
      modalities,
      instances,
      description,
      mrn,
      patientName,
      referringPhysicianName,
      date,
      time,
    } = study;


    const studyDate =
      date &&
      moment(date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
      moment(date, ['YYYYMMDD', 'YYYY.MM.DD']).format(t('Common:localDateFormat', 'MMM-DD-YYYY'));
    const studyTime =
      time &&
      moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
      moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format(
        t('Common:localTimeFormat', 'hh:mm A')
      );

    return {
      dataCY: `studyRow-${studyInstanceUid}`,
      row:
       [
              {
                key: 'patientName',
                content: patientName ? (
                  <TooltipClipboard>{patientName}</TooltipClipboard>
                ) : (
                  <span className="text-gray-700">(Empty)</span>
                ),
                gridCol: 4,
              },
              {
                key: 'mrn',
                content: <TooltipClipboard>{mrn}</TooltipClipboard>,
                gridCol: 3,
              },
              {
                key: 'studyDate',
                content: (
                  <>
                    {studyDate && <span className="mr-4 ">{studyDate}</span>}
                    {studyTime && <span>{studyTime}</span>}
                  </>
                ),
                title: `${studyDate || ''} ${studyTime || ''}`,
                gridCol: 5,
              },
              {
                key: 'description',
                content: (
                  <span>
                    <TooltipClipboard>{description}</TooltipClipboard>
                  </span>
                ),
                gridCol: 4,
              },
              {
                key: 'modality',
                content: <span>{modalities}</span>,
                title: modalities,
                gridCol: 3,
              },
              {
                key: 'referringPhysicianName',
                content: (
                  <span>
                    <TooltipClipboard>{getPrediction(referringPhysicianName)}</TooltipClipboard>
                  </span>
                ),
                gridCol: 3,
              },
              {
                key: 'report',
                content: (
                  <>
                    {/* <Icon
                    name="group-layers"
                    className={classnames('inline-flex mr-2 w-4 ', {
                      'text-primary-active': isExpanded,
                      'text-white': !isExpanded,
                    })}
                  /> */}
                    <Link
                      to={`/viewer/view-report/${studyInstanceUid}`}
                      // to={`/viewer/view-report/StudyInstanceUIDs=${studyInstanceUid}`}
                      // to={`/viewer/view-report?StudyInstanceUIDs=${studyInstanceUid}`}
                      className="hover:scale-110"
                    >
                      <Icon
                        name="clipboard"
                        className={classnames('mr-2 inline-flex w-4 hover:scale-125', {
                          'text-primary-active': isReported(studyInstanceUid),
                          'text-white': !isReported(studyInstanceUid),
                        })}
                      />
                    </Link>
                    {/* {instances} */}
                  </>
                ),
                title: 'Patient Report',
                gridCol: 4,
              },
            ],
      // Todo: This is actually running for all rows, even if they are
      // not clicked on.
      expandedContent: (
        <StudyListExpandedRow
          seriesTableColumns={{
            description: t('StudyList:Description'),
            seriesNumber: t('StudyList:Series'),
            modality: t('StudyList:Modality'),
            instances: t('StudyList:Instances'),
          }}
          seriesTableDataSource={
            seriesInStudiesMap.has(studyInstanceUid)
              ? seriesInStudiesMap.get(studyInstanceUid).map(s => {
                  return {
                    description: s.description || '(empty)',
                    seriesNumber: s.seriesNumber ?? '',
                    modality: s.modality || '',
                    instances: s.numSeriesInstances || '',
                  };
                })
              : []
          }
        >
          <div className="flex flex-row gap-2">
            {appConfig.loadedModes.map((mode, i) => {
              const modalitiesToCheck = modalities.replaceAll('/', '\\');

              const isValidMode = mode.isValidMode({
                modalities: modalitiesToCheck,
                study,
              });
              // console.log("IsValidMode: ",isValidMode)
              // console.log("appConfig.loadedModes: ",appConfig.loadedModes)
              // TODO: Modes need a default/target route? We mostly support a single one for now.
              // We should also be using the route path, but currently are not
              // mode.routeName
              // mode.routes[x].path
              // Don't specify default data source, and it should just be picked up... (this may not currently be the case)
              // How do we know which params to pass? Today, it's just StudyInstanceUIDs and configUrl if exists
              const query = new URLSearchParams();
              if (filterValues.configUrl) {
                query.append('configUrl', filterValues.configUrl);
              }
              query.append('StudyInstanceUIDs', studyInstanceUid);
              return (
                mode.displayName && (
                  <Link
                    className={isValidMode.valid ? '' : 'cursor-not-allowed'}
                    key={i}
                    to={`${dataPath ? '../../' : ''}${mode.routeName}${
                      dataPath || ''
                    }?${query.toString()}`}
                    onClick={event => {
                      // In case any event bubbles up for an invalid mode, prevent the navigation.
                      // For example, the event bubbles up when the icon embedded in the disabled button is clicked.
                      if (!isValidMode.valid) {
                        event.preventDefault();
                      }
                    }}
                    // to={`${mode.routeName}/dicomweb?StudyInstanceUIDs=${studyInstanceUid}`}
                  >
                    {/* TODO revisit the completely rounded style of buttons used for launching a mode from the worklist later - for now use LegacyButton*/}
                    <LegacyButton
                      rounded="full"
                      variant={isValidMode.valid ? 'contained' : 'disabled'}
                      disabled={!isValidMode.valid}
                      endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
                      onClick={() => {
                        setIsInsideViewer(true)
                        localStorage.setItem(
                          'patient-info',
                          JSON.stringify(
                            sortedStudies.find(
                              (item, key) => item.studyInstanceUid === studyInstanceUid
                            )
                          )
                        );
                        // secureLocalStorage.setItem(
                        //   'patient-info',
                        //   JSON.stringify(
                        //     sortedStudies.find(
                        //       (item, key) => item.studyInstanceUid === studyInstanceUid
                        //     )
                        //   )
                        // );
                        console.log("isInsideViewer is lagecy: ",isInsideViewer)
                        secureLocalStorage.setItem('Modality', 'Default');
                        secureLocalStorage.setItem('template', '');
                      }}
                      data-cy={`mode-${mode.routeName}-${studyInstanceUid}`}
                    >
                      {mode.displayName}
                    </LegacyButton>
                  </Link>
                )
              );
            })}
          </div>
        </StudyListExpandedRow>
      ),
      onClickRow: () =>
        setExpandedRows(s => (isExpanded ? s.filter(n => rowKey !== n) : [...s, rowKey])),
      isExpanded,
    };
  });

  // 8888

  const hasStudies = numOfStudies > 0;
  const versionNumber = '3.0';
  // const commitHash = process.env.COMMIT_HASH;

  const menuOptions = [
    {
      title: t('Header:About'),
      icon: 'info',
      onClick: () =>
        show({
          content: AboutModal,
          title: 'About XyCAD Viewer',
          contentProps: { versionNumber },
        }),
    },
    // {
    //   title: t('Header:Preferences'),
    //   icon: 'settings',
    //   onClick: () =>
    //     show({
    //       title: t('UserPreferencesModal:User preferences'),
    //       content: UserPreferences,
    //       contentProps: {
    //         hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
    //         hotkeyDefinitions,
    //         onCancel: hide,
    //         currentLanguage: currentLanguage(),
    //         availableLanguages,
    //         defaultLanguage,
    //         // onSubmit: state => {
    //         //   if (state.language.value !== currentLanguage().value) {
    //         //     i18n.changeLanguage(state.language.value);
    //         //   }
    //         //   hotkeysManager.setHotkeys(state.hotkeyDefinitions);
    //         //   hide();
    //         // },
    //         onSubmit: ({ hotkeyDefinitions, language }) => {
    //           if (language.value !== currentLanguage().value) {
    //             i18n.changeLanguage(language.value);
    //           }
    //           hotkeysManager.setHotkeys(hotkeyDefinitions);
    //           hide();
    //         },
    //         onReset: () => hotkeysManager.restoreDefaultBindings(),
    //         hotkeysModule: hotkeys,
    //       },
    //     }),
    // },
    {
      icon: 'power-off',
      title: t('Header:Logout'),
      onClick: () => {
        services.logout();
      },
    },
  ];
  // const pacsOptions = [
  //   {
  //     title: t('Header:About'),
  //     icon: 'info',
  //     onClick: () =>
  //       show({
  //         content: AboutModal,
  //         title: 'About XyCAD Viewer',
  //         contentProps: { versionNumber, buildNumber },
  //       }),
  //   },
  //   {
  //     title: t('Header:Preferences'),
  //     icon: 'settings',
  //     onClick: () =>
  //       show({
  //         title: t('UserPreferencesModal:User Preferences'),
  //         content: UserPreferences,
  //         contentProps: {
  //           hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
  //           hotkeyDefinitions,
  //           onCancel: hide,
  //           currentLanguage: currentLanguage(),
  //           availableLanguages,
  //           defaultLanguage,
  //           onSubmit: state => {
  //             i18n.changeLanguage(state.language.value);
  //             hotkeysManager.setHotkeys(state.hotkeyDefinitions);
  //             hide();
  //           },
  //           onReset: () => hotkeysManager.restoreDefaultBindings(),
  //           hotkeysModule: hotkeys,
  //         },
  //       }),
  //   },
  //   {
  //     icon: 'power-off',
  //     title: t('Header:Logout'),
  //     onClick: () => {
  //       userServices.logout();
  //     },
  //   },
  // ];

  if (appConfig.oidc) {
    menuOptions.push({
      icon: 'power-off',
      title: t('Header:Logout'),
      onClick: () => {
        navigate(`/logout?redirect_uri=${encodeURIComponent(window.location.href)}`);
      },
    });
  }

  const { customizationService } = servicesManager.services;
  const { component: dicomUploadComponent } =
    customizationService.get('dicomUploadComponent') ?? {};
  const uploadProps =
    dicomUploadComponent && dataSource.getConfig()?.dicomUploadEnabled
      ? {
          title: 'Upload files',
          closeButton: true,
          shouldCloseOnEsc: false,
          shouldCloseOnOverlayClick: false,
          content: dicomUploadComponent.bind(null, {
            dataSource,
            onComplete: () => {
              hide();
              onRefresh();
            },
            onStarted: () => {
              show({
                ...uploadProps,
                // when upload starts, hide the default close button as closing the dialogue must be handled by the upload dialogue itself
                closeButton: false,
              });
            },
          }),
        }
      : undefined;

  const { component: dataSourceConfigurationComponent } =
    customizationService.get('ohif.dataSourceConfigurationComponent') ?? {};

  return (
    <div className="flex h-screen w-full flex-col bg-black">
      <Header
        isSticky
        menuOptions={menuOptions}
        isReturnEnabled={false}
        WhiteLabeling={appConfig.whiteLabeling}
        // pacsOptions={pacsOptions}
      />
      <div className="ohif-scrollbar h-screen overflow-y-auto">
        <StudyListFilter
          numOfStudies={pageNumber * resultsPerPage > 100 ? 101 : numOfStudies}
          filtersMeta={filtersMeta}
          filterValues={{ ...filterValues, ...defaultSortValues }}
          onChange={setFilterValues}
          clearFilters={() => {
            setFilterValues(defaultFilterValues);
            setIsTriaged(false);
            setFilteredList(studies)
          }}
          isTriaged={isTriaged}
          setIsTriaged={val => setIsTriaged(val)}
          triageFilters={() => {
            setFilterValues(triagedFilters);
            setIsTriaged(true);
          }}
          isFiltering={isFiltering(filterValues, defaultFilterValues)}
          onUploadClick={uploadProps ? () => show(uploadProps) : undefined}
          getDataSourceConfigurationComponent={
            dataSourceConfigurationComponent ? () => dataSourceConfigurationComponent() : undefined
          }
        />
        {hasStudies ? (
          <div className="flex grow flex-col">
            <StudyListTable
              tableDataSource={tableDataSource.slice(offset, offsetAndTake)}
              numOfStudies={numOfStudies}
              querying={querying}
              filtersMeta={filtersMeta}
            />
            <div className="grow">
              <StudyListPagination
                onChangePage={onPageNumberChange}
                onChangePerPage={onResultsPerPageChange}
                currentPage={pageNumber}
                perPage={resultsPerPage}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center pt-48">
            {appConfig.showLoadingIndicator && isLoadingData ? (
              <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
            ) : (
              <EmptyStudies />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

WorkList.propTypes = {
  data: PropTypes.array.isRequired,
  dataSource: PropTypes.shape({
    query: PropTypes.object.isRequired,
    getConfig: PropTypes.func,
  }).isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  servicesManager: PropTypes.instanceOf(ServicesManager),
};

const defaultFilterValues = {
  patientName: '',
  mrn: '',
  studyDate: {
    startDate: null,
    endDate: null,
  },
  description: '',
  modalities: [],
  referringPhysicianName: [],
  accession: '',
  sortBy: 'studyDate',
  sortDirection: 'ascending',
  pageNumber: 1,
  resultsPerPage: 25,
  datasources: '',
  configUrl: null,
};
const triagedFilters = {
  patientName: '',
  mrn: '',
  studyDate: {
    startDate: null,
    endDate: null,
  },
  description: '',
  modalities: ['CR', 'DX'],
  referringPhysicianName: [],
  accession: '',
  sortBy: 'referringPhysicianName',
  sortDirection: 'ascending',
  pageNumber: 1,
  resultsPerPage: 25,
  datasources: '',
  configUrl: null,
};

function _tryParseInt(str, defaultValue) {
  let retValue = defaultValue;
  if (str && str.length > 0) {
    if (!isNaN(str)) {
      retValue = parseInt(str);
    }
  }
  return retValue;
}

function _getQueryFilterValues(params) {
  const queryFilterValues = {
    patientName: params.get('patientname'),
    mrn: params.get('mrn'),
    studyDate: {
      startDate: params.get('startdate') || null,
      endDate: params.get('enddate') || null,
    },
    description: params.get('description'),
    modalities: params.get('modalities') ? params.get('modalities').split(',') : [],
    referringPhysicianName: params.get('referringPhysicianName')
      ? params.get('referringPhysicianName').split(',')
      : [],
    accession: params.get('accession'),
    sortBy: params.get('sortby'),
    sortDirection: params.get('sortdirection'),
    pageNumber: _tryParseInt(params.get('pagenumber'), undefined),
    resultsPerPage: _tryParseInt(params.get('resultsperpage'), undefined),
    datasources: params.get('datasources'),
    configUrl: params.get('configurl'),
  };
  // console.log(
  //   'queryFilterValues.referringPhysicianName: ',
  //   queryFilterValues.referringPhysicianName
  // );

  // Delete null/undefined keys
  Object.keys(queryFilterValues).forEach(
    key => queryFilterValues[key] == null && delete queryFilterValues[key]
  );

  return queryFilterValues;
}

function _sortStringDates(s1, s2, sortModifier) {
  // TODO: Delimiters are non-standard. Should we support them?
  const s1Date = moment(s1.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);
  const s2Date = moment(s2.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);

  if (s1Date.isValid() && s2Date.isValid()) {
    return (s1Date.toISOString() > s2Date.toISOString() ? 1 : -1) * sortModifier;
  } else if (s1Date.isValid()) {
    return sortModifier;
  } else if (s2Date.isValid()) {
    return -1 * sortModifier;
  }
}

export default WorkList;
