import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

function getStoredValue(key, initialValue) {
  const storedValue = secureLocalStorage.getItem(key);
  if (storedValue) return storedValue;

  if (initialValue instanceof Function) return initialValue();
  return initialValue;
}

function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    return getStoredValue(key, initialValue);
  });
  useEffect(() => {
    secureLocalStorage.setItem(key, value);
  }, [value]);

  return [value, setValue];
}

export default useLocalStorage;
